import { css } from 'linaria'

import { fontWeights } from './styles/typography'


css`
  :global() {
    #root {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }

    /* Updates normalise.css with bootstrap reboot.css */
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    html {
      font-family: sans-serif;
      line-height: 1.15;

      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
      display: block;
    }

    body {
      margin: 0;
      color: #212529;
      font-weight: ${fontWeights.normal};
      font-size: 1rem;
      font-family:
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        "Helvetica Neue",
        Arial,
        "Noto Sans",
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
        "Noto Color Emoji";
      line-height: 1.5;
      text-align: left;
      background-color: #fff;
    }

    [tabindex="-1"]:focus:not(:focus-visible) {
      outline: 0 !important;
    }

    hr {
      box-sizing: content-box;
      height: 0;
      overflow: visible;
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    p {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    abbr[title],
    abbr[data-original-title] {
      text-decoration: underline dotted;
      border-bottom: 0;
      cursor: help;
      text-decoration-skip-ink: none;
    }

    address {
      margin-bottom: 1rem;
      font-style: normal;
      line-height: inherit;
    }

    ol,
    ul,
    dl {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    ol ol,
    ul ul,
    ol ul,
    ul ol {
      margin-bottom: 0;
    }

    dt {
      font-weight: ${fontWeights.bold};
    }

    dd {
      margin-bottom: 0.5rem;
      margin-left: 0;
    }

    blockquote {
      margin: 0 0 1rem;
    }

    b,
    strong {
      font-weight: ${fontWeights.bold};
    }

    small {
      font-size: 80%;
    }

    sub,
    sup {
      position: relative;
      font-size: 75%;
      line-height: 0;
      vertical-align: baseline;
    }

    sub {
      bottom: -0.25em;
    }

    sup {
      top: -0.5em;
    }

    a {
      text-decoration: none;
      background-color: transparent;
    }

    a:hover {
      text-decoration: underline;
    }

    a:not([href]):not([class]) {
      color: inherit;
      text-decoration: none;
    }

    a:not([href]):not([class]):hover {
      color: inherit;
      text-decoration: none;
    }

    pre,
    code,
    kbd,
    samp {
      font-size: 1em;
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    }

    pre {
      margin-top: 0;
      margin-bottom: 1rem;
      overflow: auto;
      -ms-overflow-style: scrollbar;
    }

    figure {
      margin: 0 0 1rem;
    }

    img {
      vertical-align: middle;
      border-style: none;
    }

    svg {
      max-width: 100%;
      height: auto;
      overflow: hidden;
      vertical-align: middle;
    }

    table {
      border-collapse: collapse;
    }

    caption {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      color: #6c757d;
      text-align: left;
      caption-side: bottom;
    }

    th {
      text-align: inherit;
      text-align: -webkit-match-parent;
    }

    label {
      display: inline-block;
      margin-bottom: 0.5rem;
    }

    button {
      border-radius: 0;
    }

    button:focus {
      outline: none;
    }

    input,
    button,
    select,
    optgroup,
    textarea {
      margin: 0;
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
    }

    button,
    input {
      overflow: visible;
    }

    button,
    select {
      text-transform: none;
    }

    [role="button"] {
      cursor: pointer;
    }

    select {
      word-wrap: normal;
    }

    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
      appearance: button;
    }

    button:not(:disabled),
    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled) {
      cursor: pointer;
    }

    button::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
      padding: 0;
      border-style: none;
    }

    input[type="radio"],
    input[type="checkbox"] {
      box-sizing: border-box;
      padding: 0;
    }

    textarea {
      overflow: auto;
      resize: vertical;
    }

    fieldset {
      min-width: 0;
      margin: 0;
      padding: 0;
      border: 0;
    }

    legend {
      display: block;
      width: 100%;
      max-width: 100%;
      margin-bottom: 0.5rem;
      padding: 0;
      color: inherit;
      font-size: 1.5rem;
      line-height: inherit;
      white-space: normal;
    }

    progress {
      vertical-align: baseline;
    }

    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
      height: auto;
    }

    [type="search"] {
      outline-offset: -2px;
      appearance: none;
    }

    [type="search"]::-webkit-search-decoration {
      appearance: none;
    }

    ::-webkit-file-upload-button {
      font: inherit;
      appearance: button;
    }

    output {
      display: inline-block;
    }

    summary {
      display: list-item;
      cursor: pointer;
    }

    template {
      display: none;
    }

    [hidden] {
      display: none !important;
    }

    @media print {
      html,
      body {
        margin: 0;
        padding: 0;
        border: 0;
      }
    }
  }
`
