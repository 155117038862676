import { css } from 'linaria'

import NunitoLight from './Nunito-Light.woff2'
import NunitoRegular from './Nunito-Regular.woff2'
import NunitoBold from './Nunito-Bold.woff2'
import { fontWeights } from '../styles/typography'


export const fonts = css`
  :global() {
    @font-face {
      font-weight: ${fontWeights.light};
      font-family: 'Nunito';
      src: url(${NunitoLight}) format('woff2');
    }

    @font-face {
      font-weight: ${fontWeights.normal};
      font-family: 'Nunito';
      src: url(${NunitoRegular}) format('woff2');
    }

    @font-face {
      font-weight: ${fontWeights.bold};
      font-family: 'Nunito';
      src: url(${NunitoBold}) format('woff2');
    }
  }
`
